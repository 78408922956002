import React from 'react';
import { Content, PageWithHeader, SupportButton } from '@backstage/core-components';


export const TaskPage = () => {
  return (
    <PageWithHeader themeId="component" title="Task Management">
      {/* Increase right padding to push the button further to the right */}

      <Content>
        <div style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '40px', paddingTop: '10px' }}>
          <SupportButton>Tasks on the project.</SupportButton>
        </div>
      </Content>
    </PageWithHeader>
  );
};

