import React from 'react';
import { Route, Routes, Link, useLocation } from 'react-router-dom';
import { Grid, Tabs, Tab, Typography, Card } from '@material-ui/core';
import { Content, PageWithHeader, SupportButton } from '@backstage/core-components';
import { MarkdownPage } from './Docs';


const InDevPlaceholder = ({  }) => (
  <Card style={{height: 'calc(100dvh - 200px)'}}>
    <div style={{
      display:'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%'
    }}
    className='empty-search-div'>
      <img src='/empty_search.png' style={{ maxWidth: '1000px', width: '100%', height: '100%', objectFit: 'contain' }}></img></div>
  </Card>
);


const TabNavigation = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <Tabs
      value={currentPath}
      aria-label="security navigation tabs"
      variant="scrollable"
      indicatorColor="primary"
      textColor="primary"
    >
      <Tab label="Overview" value="/security" component={Link} to="/security" />
      <Tab label="Docs" value="/security/docs" component={Link} to="/security/docs" />
    </Tabs>
  );
};

// Main Security Page Component
export const SecurityPage = () => (
  <PageWithHeader themeId="tool" title="Security and Compliance">
    <Content>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
        <TabNavigation />
        <SupportButton>Project security page.</SupportButton>
      </div>
      <Routes>
        <Route path="/" element={<MarkdownPage fileName={'/docs/home/security.md'} />} />
        <Route path="/docs" element = {<MarkdownPage fileName={'/docs/home/security.md'} />} />
      </Routes>
    </Content>
  </PageWithHeader>
);