import { useState, useEffect } from 'react';
import { Grid, Tabs, Tab, Box, Typography } from '@material-ui/core';
import { Content, PageWithHeader, SupportButton } from '@backstage/core-components';
import {  styled } from '@material-ui/core/styles';
import React from 'react';
import { MarkdownPage } from './Docs';

const StyledTabs = styled(Tabs)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));
const Overview = () => (
  <Box my={4}>
    <Grid container spacing={4}>
      <Grid item xs={12} md={12}>
        <CostInfo />
      </Grid>
      <Grid item xs={12} md={12}>
        <CostDashboard />
      </Grid>
    </Grid>
  </Box>
);

// const CustomCostInsightsPage = () => {
//   useEffect(() => {
//     const hideHeader = () => {
//       const headers = document.getElementsByTagName('header');
//       if (headers.length > 1) {
//         headers[1].style.display = 'none';
//       }
//     };

//     // Delay hiding the header to ensure it’s fully loaded
//     const timeoutId = setTimeout(hideHeader, 20); // Adjust time if needed

//     return () => clearTimeout(timeoutId); // Clean up on component unmount
//   }, []);

//   return (
//     <Grid container spacing={3}>
//       <Grid item xs={12}>
//         <CostInsightsPage />
//       </Grid>
//     </Grid>
//   );
// };
// export default CustomCostInsightsPage;

export const Billing = () => {
  const [activeTab, setActiveTab] = useState('overview'); // Default tab is "overview"

  const handleChange = (_event: any, newValue: React.SetStateAction<string>) => {
    setActiveTab(newValue);
  };

  return (
    <PageWithHeader themeId="library" title="Billing Information">
      <Content>
        <Box mb={3}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
          <StyledTabs value={activeTab} onChange={handleChange} aria-label="billing navigation tabs" indicatorColor="primary" textColor="primary" variant="scrollable" scrollButtons="auto">
            <Tab label="Overview" value="overview" />
            {/* <Tab label="Cost Insights" value="cost-insights" /> */}
            <Tab label="Docs" value="docs" />
          </StyledTabs>
          <SupportButton>Billing page.</SupportButton>
          </div>
        </Box>

        <Grid container spacing={3}>
          {activeTab === 'overview' && (
            <Grid item xs={12}>
              <MarkdownPage fileName={'/home.md'} />
            </Grid>
          )}

          {/* {activeTab === 'cost-insights' && (
            <Grid item xs={12}>
              <CustomCostInsightsPage />
            </Grid>
          )} */}

          {activeTab === 'docs' && (
            <Grid item xs={12}>
              <MarkdownPage fileName={'/home.md'} />
            </Grid>
          )}
        </Grid>
      </Content>
    </PageWithHeader>
  );
};
